<template>
  <div
    class="person-avatar"
    :class="{ 'person-avatar--white': white }"
  >
    <img
      src="@/assets/img/user@2x.png"
      alt="Avatar"
      width="16"
      height="16"
    >
  </div>
</template>

<script>
export default {
  props: {
    white: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style lang="scss" scoped>
.person-avatar {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  width: 41px;
  height: 41px;
  background-color: $color-fake-white;
  border-radius: 50%;

  &.person-avatar--white {
    background-color: white;
  }
}
</style>
