<template>
  <div>
    <div
      v-if="isBubbleShown"
      class="bubble-shadow"
    />
    <div
      ref="bubbleButtonContainer"
      class="bubble-button-container"
    >
      <button
        class="participants-button"
        :class="{ 'participants-button--is-active': isBubbleShown }"
        @click="showBubble"
      >
        {{ participants.length }} {{ $tc(`ParticipantsBubbleButton.${type}`, participants.length) }}
      </button>
      <ParticipantsBubble
        v-if="isBubbleShown"
        class="participants-bubble-component"
        :participants="participants"
      />
    </div>
  </div>
</template>

<script>
import ParticipantsBubble from '@/components/ParticipantsBubble.vue'

export default {
  components: {
    ParticipantsBubble
  },
  props: {
    participants: {
      type: Array,
      required: true
    },
    type: {
      type: String,
      default: 'invited'
    }
  },
  data () {
    return {
      isBubbleShown: false
    }
  },
  watch: {
    isBubbleShown: {
      handler (value) {
        const zIndex = value ? '1' : '2'
        const appHeaderElement = window.document.getElementById('app-header')

        if (appHeaderElement) {
          appHeaderElement.style.zIndex = zIndex
        }
      },
      immediate: true
    }
  },
  mounted () {
    document.addEventListener('mousedown', this.handleMousedown)
  },
  beforeDestroy () {
    document.removeEventListener('mousedown', this.handleMousedown)
  },
  methods: {
    handleMousedown (event) {
      if (this.$refs.bubbleButtonContainer.contains(event.target) === false) {
        this.isBubbleShown = false
      }
    },
    showBubble () {
      if (this.participants.length > 0) {
        this.isBubbleShown = true
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.bubble-shadow {
  position: fixed;
  z-index: $z-overlay-shadow;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(255, 255, 255, 0.7);
  cursor: default;
}

.bubble-button-container {
  position: relative;
}

.participants-button {
  padding: 8px 8px 6px;
  width: 130px;
  background-color: white;
  border: 1px solid $color-gray-light;
  border-radius: 16px;
  color: $color-gray-mid;
  font-size: 12px;
  line-height: 18px;

  &.participants-button--is-active {
    background-color: rgba(0, 98, 255, 0.1);
    border-color: #1e75ff;
    color: $color-blue;
  }
}

.participants-bubble-component {
  position: absolute;
  z-index: $z-overlay-box;
  top: 40px;
  left: -75px;

  @include above(small) {
    top: -80px;
    left: -348px - 20px;
  }
}
</style>
