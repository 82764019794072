<template>
  <div class="participants-bubble">
    <div
      v-for="(participant, index) in participants"
      :key="index"
      class="participant-container"
    >
      <PersonAvatar />
      <p class="participant-name">
        {{ participant.name }}
      </p>
      <p
        class="participant-parenthesis"
      >
        <template v-if="participant.cli_number">
          {{ participant.cli_number }}<br>
        </template>
        {{ participant.email }}
      </p>
    </div>
  </div>
</template>

<script>
import PersonAvatar from '@/components/PersonAvatar.vue'

export default {
  components: {
    PersonAvatar
  },
  props: {
    participants: {
      type: Array,
      required: true
    }
  }
}
</script>

<style lang="scss" scoped>
.participants-bubble {
  padding: 20px;
  width: 348px;
  max-width: calc(100vw - 20px);
  max-height: 530px;
  background-color: white;
  border-radius: 20px;
  box-shadow: 0 10px 40px 0 rgba(0, 0, 0, 0.08);
  overflow-y: auto;
  cursor: default;
}

.participant-container {
  display: flex;
  align-items: center;

  &:not(:first-child) {
    margin-top: 20px;
  }
}

.participant-name {
  margin-left: 15px;
  font-size: 14px;
  white-space: nowrap;
}

.participant-parenthesis {
  margin-left: 10px;
  color: $color-gray-soft;
  font-size: 12px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
</style>
