<template>
  <div class="view-root">
    <section class="side-section">
      <header class="header">
        <div class="A_icon-box A_icon-box--white">
          <img
            src="@/assets/img/ongoing.svg"
            width="16"
            height="16"
          >
        </div>
        <p class="header-conf-title">
          {{ room.name }}
        </p>
        <div>
          <button
            class="header-pencil-button"
            @click="openEditConfSidebar"
          >
            <img
              src="@/assets/img/pencil.svg"
              width="22"
              height="22"
            >
          </button>
        </div>
        <!-- <div class="header-edit-button-container">
          <ds-button
            size="small"
            white
            class="header-edit-button"
            @click="openEditConfSidebar"
          >
            {{ $t('Conference.editButton') }}
          </ds-button>
        </div> -->
      </header>
      <div
        v-if="room && room.medias && room.medias.welcome && room.medias.welcome.url"
        class="media-container"
      >
        {{ $t('Conference.customWelcomeMessage') }}
        <div class="player-container">
          <audio
            class="audio-player"
            controls
          >
            <source
              :src="room.medias.welcome.url"
              type="audio/mpeg"
            >
          </audio>
        </div>
      </div>
      <div style="margin-top: 20px;">
        <ds-button
          size="small"
          @click="openEditConfSidebar"
        >
          {{ $t('Conference.modifyButton') }}
        </ds-button>
      </div>
      <div
        v-if="!hasConnected"
        style="margin-top: 10px;"
      >
        <ds-button
          size="small"
          danger
          @click="remove"
        >
          {{ $t('Conference.deleteButton') }}
        </ds-button>
      </div>
      <transition
        mode="out-in"
        name="slide"
      >
        <div v-if="hasConnected">
          <div class="conf-number-container">
            <p class="T_small-heading">
              {{ $t('Conference.conferenceNumber') }}
            </p>
            <p class="conf-number">
              {{ localPhoneNumber }}
            </p>
          </div>
          <div class="pin-items-container">
            <div class="pin-item-container">
              <p class="T_small-heading">
                {{ $t('Conference.guestPin') }}
              </p>
              <p class="pin-item-code">
                {{ room.pin_user }}
              </p>
            </div>
            <div class="pin-item-container">
              <p class="T_small-heading">
                {{ $t('Conference.adminPin') }}
              </p>
              <p class="pin-item-code">
                {{ room.pin_admin }}
              </p>
            </div>
          </div>
          <div class="invite-button-container">
            <ds-button
              size="medium"
              @click="openInvitePopin"
            >
              {{ $t('Conference.inviteButton') }}
            </ds-button>
          </div>
          <button
            v-if="room.invited.length > 0"
            class="invitees-button"
            @click="openEditConfSidebar"
          >
            ({{ room.invited.length }} {{ $tc('Conference.invited', room.invited.length) }})
          </button>
        </div>
      </transition>
    </section>

    <div v-if="error">
      {{ error }}
    </div>

    <section class="conf-section">
      <transition
        mode="out-in"
        name="slide"
      >
        <div v-if="!hasConnected">
          <div v-if="room.next_meeting && room.next_meeting.substr(0, 4) != '0000'">
            <div>
              <p class="T_small-heading">
                {{ $t('ScheduledConference.confStartingHeading') }}
              </p>
              <p class="conf-starting-date">
                {{ room.next_meeting | datetime }}
              </p>
            </div>
          </div>
          <div class="conf-bottom-container">
            <div>
              <p class="T_small-heading">
                {{ $t('ScheduledConference.confNumberHeading') }}
              </p>
              <p class="conf-phone-number">
                {{ room.dids && room.dids[0] && room.dids[0].local_number_f }}
              </p>
            </div>
            <div class="pin-items-container">
              <div class="pin-item-container">
                <p class="T_small-heading">
                  {{ $t('ScheduledConference.guestPin') }}
                </p>
                <p class="pin-item-code">
                  {{ room.pin_user }}
                </p>
              </div>
              <div class="pin-item-container">
                <p class="T_small-heading">
                  {{ $t('ScheduledConference.adminPin') }}
                </p>
                <p class="pin-item-code">
                  {{ room.pin_admin }}
                </p>
              </div>
            </div>
            <div class="invite-button-container">
              <ds-button
                size="medium"
                @click="openInvitePopin"
              >
                {{ $t('ScheduledConference.inviteButton') }}
              </ds-button>
            </div>
            <div style="margin-top: 10px;">
              <ds-button
                v-clipboard="copyButtonClipboardValue"
                size="small"
                outlined
              >
                {{ $t('Conference.copyButton') }}
              </ds-button>
            </div>
            <button
              v-if="room.invited.length > 0"
              class="invitees-button"
              @click="openEditConfSidebar"
            >
              {{ room.invited.length }} {{ $tc('Conference.invited', room.invited.length) }}
            </button>
          </div>
        </div>

        <!-- connected -->
        <div v-else>
          <div class="conf-duration-container">
            <p class="T_small-heading">
              {{ $t('Conference.conferenceDuration') }}
            </p>
            <p class="conf-duration">
              {{ duration(room.hash) | duration }}
            </p>
          </div>
          <div class="participants-container">
            <div
              v-for="(participant, index) in room.connected"
              :key="index"
              class="participant-box"
            >
              <PersonAvatar />
              <p class="participant-name">
                {{ participant.name }}
                <span class="participant-sub-name">
                  {{ participant.cli_number }}
                </span>
                <span
                  v-if="participant.level === 'ADMIN'"
                  class="participant-sub-name"
                >
                  (admin)
                </span>
              </p>
              <button
                class="participant-button"
                :disabled="loadingMute[participant.id]"
                @click="mute(participant)"
              >
                <img
                  :src="require(`../assets/img/micro-small${participant.muted ? '-muted' : ''}.svg`)"
                  alt=""
                  width="19"
                  height="19"
                >
              </button>
              <button
                class="participant-button"
                :disabled="loadingPause[participant.id]"
                @click="pause(participant)"
              >
                <img
                  :src="require(`@/assets/img/${participant.paused ? 'play' : 'hold'}.svg`)"
                  alt="Pause"
                  width="19"
                  height="19"
                >
              </button>
              <button
                class="participant-button participant-button--danger"
                :disabled="loadingHangup[participant.id]"
                @click="hangup(participant)"
              >
                <img
                  src="@/assets/img/hangup.svg"
                  alt="Hang up"
                  width="17"
                  height="17"
                >
              </button>
            </div>
          </div>
          <div class="bottom-buttons-container">
            <ds-button
              size="large"
              white
              :disabled="loadingMuteAll"
              @click="muteAll"
            >
              <img
                src="@/assets/img/micro-muted.svg"
                alt="Mute"
                width="24"
                height="24"
              >
              <span>
                {{ $t('Conference.muteAllButton') }}
              </span>
            </ds-button>
            <ds-button
              class="unmute-all-button-above-small"
              size="large"
              white
              :disabled="loadingUnmuteAll"
              @click="unmuteAll"
            >
              <img
                src="@/assets/img/micro-unmuted.svg"
                alt="Unmute"
                width="24"
                height="24"
              >
              <span>
                {{ $t('Conference.unmuteAllButton') }}
              </span>
            </ds-button>
            <ds-button
              size="large"
              danger
              class="hangup-button"
              :disabled="loadingHangupAll"
              @click="hangupAll"
            >
              <img
                src="@/assets/img/hangup.svg"
                alt="Hang up"
                width="26"
                height="26"
              >
            </ds-button>
          </div>
        </div>
      </transition>
    </section>

    <section
      v-if="!$store.state.session.userPremium"
      class="go-premium-section"
    >
      <p
        class="go-premium-description"
        v-html="$t('Conference.goPremiumDescription', {
          freeConcurrentConnected,
          freeCallLimitMinutes
        })"
      />
      <router-link
        class="go-premium-cta"
        to="/profile"
      >
        {{ $t('Conference.goPremiumCta') }}
      </router-link>
    </section>

    <button
      class="settings-button-below-small"
      @click="openEditConfSidebar"
    >
      <img
        src="@/assets/img/settings.svg"
        width="20"
        height="22"
      >
    </button>

    <EditConfSidebar
      v-model="isEditConfSidebarOpened"
      :is-invite-popin-opened="isInvitePopinOpened"
      :room-id="roomId"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import PersonAvatar from '@/components/PersonAvatar.vue'
import EditConfSidebar from '@/components/EditConfSidebar.vue'

const emptyRoom = {
  connected: {},
  date_creation: '',
  date_last_use: '',
  dids: [],
  email: '',
  hash: '',
  invited: [],
  medias: {
    recordings: [],
    welcome: null
  },
  name: '',
  next_meeting: '',
  notify_connect: false,
  notify_next_meeting: 0,
  pin_admin: '',
  pin_user: '',
  stats_calls_total: null,
  stats_seconds_total: null
}

export default {
  name: 'Conference',
  components: {
    PersonAvatar,
    EditConfSidebar
  },
  data () {
    return {
      isEditConfSidebarOpened: false,
      isInvitePopinOpened: false,
      loading: false,
      error: '',
      loadingMute: {},
      loadingPause: {},
      loadingHangup: {},
      loadingMuteAll: false,
      loadingUnmuteAll: false,
      loadingHangupAll: false
    }
  },
  computed: {
    ...mapGetters('session', [
      'freeConcurrentConnected',
      'freeCallLimitMinutes'
    ]),
    room () {
      return this.$store.state.rooms.rooms[this.roomId] || emptyRoom
    },
    roomId () {
      return this.$route.params.id
    },
    hasConnected () {
      return Object.keys(this.room.connected).length > 0
    },
    ...mapGetters({
      duration: 'rooms/duration'
    }),
    localPhoneNumber () {
      return (this.room && this.room.dids && this.room.dids[0] && this.room.dids[0].local_number_f) || null
    },
    copyButtonClipboardValue () {
      return `${this.room.name}
${this.$t('Conference.copyPhoneNumberLabel')} ${this.localPhoneNumber}
${this.$t('Conference.copyGuestPinLabel')} ${this.room.pin_user}`
    }
  },
  watch: {
    isEditConfSidebarOpened (value) {
      if (value === false) {
        this.isInvitePopinOpened = false
      }
    }
  },
  async mounted () {
    if (this.$route.params.openInvitePopin) {
      this.openInvitePopin()
    }

    this.loading = true
    try {
      // this checks the id is valid + registers for guest (no api listing)
      await this.$store.dispatch('rooms/register', { roomId: this.roomId })
    } catch (err) {
      this.$store.commit('app/openErrorPopin', err)
      this.$router.push({ name: 'Conferences' })
    } finally {
      this.loading = false
    }
  },
  methods: {
    openEditConfSidebar () {
      this.isEditConfSidebarOpened = true
    },
    openInvitePopin () {
      this.openEditConfSidebar()
      this.isInvitePopinOpened = true
    },
    async mute (participant) {
      const action = participant.muted ? 'unmute' : 'mute'

      try {
        this.$set(this.loadingMute, participant.id, true)
        await this.$store.dispatch(`rooms/${action}`, {
          roomId: this.roomId,
          ids: [participant.id]
        })
        await this.$store.dispatch('rooms/refresh', { roomId: this.roomId })
      } catch (err) {
        this.$store.commit('app/openErrorPopin', {
          message: this.$t(`Errors.${err.message}`)
        })
      } finally {
        this.$delete(this.loadingMute, participant.id)
      }
    },
    async pause (participant) {
      const action = participant.paused ? 'unpause' : 'pause'

      try {
        this.$set(this.loadingPause, participant.id, true)
        await this.$store.dispatch(`rooms/${action}`, {
          roomId: this.roomId,
          ids: [participant.id]
        })
        await this.$store.dispatch('rooms/refresh', { roomId: this.roomId })
      } catch (err) {
        this.$store.commit('app/openErrorPopin', {
          message: this.$t(`Errors.${err.message}`)
        })
      } finally {
        this.$delete(this.loadingPause, participant.id)
      }
    },
    async hangup (participant) {
      try {
        this.$set(this.loadingHangup, participant.id, true)
        await this.$store.dispatch('rooms/hangup', {
          roomId: this.roomId,
          ids: [participant.id]
        })
        // wait 2s
        await new Promise((resolve) => window.setTimeout(resolve, 2000))
        await this.$store.dispatch('rooms/refresh', { roomId: this.roomId })
      } catch (err) {
        this.$store.commit('app/openErrorPopin', {
          message: this.$t(`Errors.${err.message}`)
        })
      } finally {
        this.$delete(this.loadingHangup, participant.id)
      }
    },
    async muteAll () {
      this.loadingMuteAll = true
      try {
        await this.$store.dispatch('rooms/mute', {
          roomId: this.roomId,
          ids: Object.values(this.room.connected)
            .filter((i) => i.level === 'USER')
            .map((i) => i.id)
        })
        await this.$store.dispatch('rooms/refresh', { roomId: this.roomId })
      } catch (err) {
        this.$store.commit('app/openErrorPopin', {
          message: this.$t(`Errors.${err.message}`)
        })
      } finally {
        this.loadingMuteAll = false
      }
    },
    async unmuteAll () {
      try {
        this.loadingUnmuteAll = true
        await this.$store.dispatch('rooms/unmute', {
          roomId: this.roomId,
          ids: Object.values(this.room.connected)
            .filter((i) => i.level === 'USER')
            .map((i) => i.id)
        })
        await this.$store.dispatch('rooms/refresh', { roomId: this.roomId })
      } catch (err) {
        this.$store.commit('app/openErrorPopin', {
          message: this.$t(`Errors.${err.message}`)
        })
      } finally {
        this.loadingUnmuteAll = false
      }
    },
    async hangupAll () {
      try {
        this.loadingHangupAll = true
        await this.$store.dispatch('rooms/hangup', {
          roomId: this.roomId,
          ids: Object.values(this.room.connected)
            .filter((i) => i.level === 'USER')
            .map((i) => i.id)
        })
        // wait 2s
        await new Promise((resolve) => window.setTimeout(resolve, 2000))
        await this.$store.dispatch('rooms/refresh', { roomId: this.roomId })
      } catch (err) {
        this.$store.commit('app/openErrorPopin', {
          message: this.$t(`Errors.${err.message}`)
        })
      } finally {
        this.loadingHangupAll = false
      }
    },
    async remove () {
      if (!confirm(this.$t('Conference.deleteConfirmation'))) {
        return
      }

      try {
        await this.$store.dispatch('rooms/delete', { roomId: this.roomId })
        this.$router.push({ name: 'Conferences' })
      } catch (err) {
        this.$store.commit('app/openErrorPopin', err)
      }
    }
  }
}

// connected:
// 2865523:
// cli_number: "+33664772881"
// date_connection: "2020-12-22 21:07:36"
// email: ""
// id: 2865523
// level: "USER"
// location_city: ""
// location_country: "FR"
// muted: false
// name: ""
// paused: null
// pin: "822362"
</script>

<style lang="scss" scoped>
.view-root {
  padding: 20px 20px 90px;

  @include above(small) {
    padding-bottom: 30px;
  }

  @include above(medium) {
    padding: 30px;
  }
}

.side-section {
  @include below(small) {
    display: none;
  }
}

.conf-section {
  display: flex;
  flex-direction: column;
  text-align: center;
  margin: 0 auto;
  max-width: 500px;

  @include above(small) {
    position: absolute;
    top: 196px;
    left: 50%;
    transform: translateX(-50%);
    padding-bottom: 30px;
    width: 500px;
  }

  @include above(medium) {
    top: 206px;
  }

  @include above(large) {
    top: 30px;
  }
}

.settings-button-below-small {
  position: fixed;
  bottom: 20px;
  left: 20px;

  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 50px;
  border-radius: 10px;
  background-color: white;

  @include above(small) {
    display: none;
  }
}

/*
** Side section (left part)
*/

.header {
  display: flex;
  align-items: center;
}

.header-conf-title {
  margin-left: 10px;
  color: $color-gray-soft;
  font-weight: 400;
  font-size: 18px;
}

.header-pencil-button {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  margin-left: 10px;
  width: 30px;
  height: 30px;
  border-radius: 10px;
}

.header-edit-button-container {
  margin-left: 40px;
}

.header-edit-button {
  color: $color-blue;
}

.side-section {
  .conf-number-container {
    margin-top: 20px;
  }

  .conf-number {
    color: $color-blue;
    font-weight: 600;
    font-size: 24px;
    line-height: 36px;
    letter-spacing: 0.1px;
  }

  .pin-items-container {
    display: flex;
    margin-top: 20px;
  }

  .pin-item-container:not(:first-child) {
    margin-left: 20px;
  }

  .pin-item-code {
    font-weight: 600;
    font-size: 18px;
  }

  .invite-button-container {
    margin-top: 20px;
  }
}

.invitees-button {
  margin-top: 20px;
  color: $color-blue;
  font-weight: 600;
  font-size: 12px;
  text-decoration: underline;

  @include above(small) {
    margin-top: 30px;
  }
}

/*
** Conf section (central part)
*/

.conf-section {
  .conf-starting-date {
    font-weight: 600;
    font-size: 24px;
    line-height: 36px;
    letter-spacing: 0.1px;
  }

  .conf-ending-container {
    margin-top: 10px;
  }

  .conf-ending-heading {
    font-size: 10px;
  }

  .conf-ending-date {
    font-weight: 600;
    font-size: 14px;
    letter-spacing: 0.06px;
  }

  .conf-bottom-container {
    margin-top: 120px;

    @include above(large) {
      margin-top: 160px;
    }
  }

  .conf-phone-number {
    color: $color-blue;
    font-weight: 600;
    font-size: 36px;
    letter-spacing: 0.15px;
  }

  .pin-items-container {
    display: flex;
    justify-content: center;
    margin-top: 20px;
  }

  .pin-item-container:not(:first-child) {
    margin-left: 20px;
  }

  .pin-item-code {
    font-weight: 600;
    font-size: 18px;
  }

  .invite-button-container {
    margin-top: 20px;
  }
}

.conf-duration-container {
  text-align: center;
}

.conf-duration {
  font-weight: 600;
  font-size: 28px;
  letter-spacing: 0.12px;
}

.participants-container {
  margin-top: 30px;
  overflow-y: auto;

  @include above(small) {
    margin-top: 60px;
  }

  button:disabled {
    animation: opacity_animation 1s linear infinite;
  }
}

.participant-box {
  display: flex;
  align-items: center;
  padding: 7px 10px;
  background-color: white;
  border-radius: 10px;

  &:not(:first-child) {
    margin-top: 10px;
  }
}

.participant-name {
  margin-left: 15px;
  margin-right: auto;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.participant-sub-name {
  color: $color-gray-soft;
  font-size: 12px;
}

.participant-button {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  margin-left: 10px;
  width: 35px;
  height: 35px;
  background-color: $color-fake-white;
  border-radius: 50%;

  @include above(small) {
    margin-left: 20px;
  }

  &.participant-button--danger {
    background-color: $color-red;
  }
}

.bottom-buttons-container {
  display: flex;
  justify-content: center;
  align-items: center;

  @include below(small) {
    position: fixed;
    bottom: 20px;
    right: 20px;
  }

  @include above(small) {
    margin-top: 30px;
  }

  > *:not(:first-child) {
    margin-left: 10px;

    @include above(small) {
      margin-left: 20px;
    }
  }

  button:disabled {
    animation: opacity_animation 1s linear infinite;
  }
}

.unmute-all-button-above-small {
  @include below(small) {
    display: none;
  }
}

.hangup-button {
  @include above(small) {
    width: 130px;
  }
}

.media-container {
  margin-top: 20px;
}

.audio-player {
  padding: 2px;
}

/*
** Go premium section (right part)
*/

.go-premium-section {
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  position: absolute;
  top: 20px;
  right: 20px;

  @include above(medium) {
    top: 30px;
    right: 30px;
  }

  @include below(small) {
    display: none;
  }
}

.go-premium-description {
  color: $color-gray-mid;
  font-size: 11px;
  text-align: right;
}

.go-premium-cta {
  color: $color-blue;
  font-weight: 600;
  font-size: 12px;
}
</style>

<style>
@keyframes opacity_animation {
  0% { opacity: 0.90 }
  50% { opacity: 0.25 }
  100% { opacity: 0.90 }
}
</style>
