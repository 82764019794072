<template>
  <div class="view-root">
    <section>
      <div class="rows-container">
        <p class="T_medium-heading">
          {{ $t('Conferences.ongoingHeading') }}
          <transition name="fade">
            <vue-loading
              v-if="loading"
              type="bars"
              color="#20bf6b"
              style="float: right"
            />
          </transition>
        </p>
        <transition
          mode="out-in"
          name="slide"
        >
          <div
            v-if="ongoingConferences.length === 0"
            class="row-box row-box--empty"
          >
            {{ $t('Conferences.noOngoingConf') }}
          </div>
          <div v-else>
            <router-link
              v-for="(conf, index) in ongoingConferences"
              :key="index"
              class="row-box"
              :to="{ name: 'Conference', params: { id: conf.hash } }"
            >
              <div class="A_icon-box row-icon">
                <img
                  src="@/assets/img/ongoing.svg"
                  width="16"
                  height="16"
                >
              </div>
              <p class="row-name">
                {{ conf.name }}
              </p>
              <p class="row-datetime">
                {{ $t('Conferences.started') }}
                {{ conf.connected | oldest | datetime }}
              </p>
              <p class="row-duration">
                {{ duration(conf.hash) | duration }}
              </p>
              <ParticipantsBubbleButton
                v-if="conf.connected"
                class="participants-bubble-button-component"
                :participants="Object.values(conf.connected)"
                type="connected"
                @click.native.prevent
              />
              <div class="row-action">
                <p>
                  {{ $t('Conferences.ongoing') }}
                </p>
              </div>
            </router-link>
          </div>
        </transition>
      </div>
      <div
        v-if="permanentRooms.length > 0"
        class="rows-container"
      >
        <p class="T_medium-heading">
          {{ $t('Conferences.continuousHeading') }}
          <transition name="fade">
            <vue-loading
              v-if="loading"
              type="bars"
              color="#20bf6b"
              style="float: right"
            />
          </transition>
        </p>
        <router-link
          v-for="(conf, index) in permanentRooms"
          :key="index"
          class="row-box"
          :to="{ name: 'Conference', params: { id: conf.hash } }"
        >
          <div class="A_icon-box row-icon">
            <img
              src="@/assets/img/infinity.svg"
              width="16"
              height="16"
            >
          </div>
          <p class="row-name">
            {{ conf.name }}
          </p>
          <p class="row-datetime">
            {{ $t('Conferences.created') }}
            {{ conf.date_creation | datetime }}
          </p>
          <p class="row-duration" />
          <div class="row-action">
            <router-link
              class="row-action-button"
              :to="{ name: 'Conference', params: { id: conf.hash, openInvitePopin: true } }"
            >
              <img
                src="@/assets/img/add-user.svg"
                alt="Add"
                width="24"
                height="24"
              >
            </router-link>
          </div>
        </router-link>
      </div>
      <div class="rows-container">
        <p class="T_medium-heading">
          {{ $t('Conferences.futureHeading') }}
          <transition name="fade">
            <vue-loading
              v-if="loading"
              type="bars"
              color="#20bf6b"
              style="float: right"
            />
          </transition>
        </p>
        <transition
          mode="out-in"
          name="slide"
        >
          <div
            v-if="upcomingConferences.length === 0"
            class="row-box row-box--empty"
          >
            {{ $t('Conferences.noUpcomingConf') }}
          </div>
          <div v-else>
            <router-link
              v-for="(conf, index) in upcomingConferences"
              :key="index"
              class="row-box"
              :to="{ name: 'Conference', params: { id: conf.hash } }"
            >
              <div class="A_icon-box row-icon">
                <img
                  src="@/assets/img/scheduled.svg"
                  width="16"
                  height="16"
                >
              </div>
              <p class="row-name">
                {{ conf.name }}
              </p>
              <p class="row-datetime">
                <template v-if="conf.next_meeting && conf.next_meeting.substr(0, 4) != '0000'">
                  {{ $t('Conferences.scheduled') }}
                  {{ conf.next_meeting | datetime }}
                </template>
                <template v-else>
                  {{ $t('Conferences.created') }}
                  {{ conf.date_creation | datetime }}
                </template>
              </p>
              <p class="row-duration" />
              <ParticipantsBubbleButton
                v-if="conf.invited"
                class="participants-bubble-button-component"
                :participants="conf.invited"
                @click.native.prevent
              />
              <div class="row-action">
                <router-link
                  class="row-action-button"
                  :to="{ name: 'Conference', params: { id: conf.hash, openInvitePopin: true } }"
                >
                  <img
                    src="@/assets/img/add-user.svg"
                    alt="Add"
                    width="24"
                    height="24"
                  >
                </router-link>
              </div>
            </router-link>
          </div>
        </transition>
        <!-- <div
          v-if="false"
          class="rows-container"
        >
          <p class="T_medium-heading">
            {{ $t('Conferences.endedHeading') }}
            <transition name="fade">
              <vue-loading
                v-if="loading"
                type="bars"
                color="#20bf6b"
                style="float: right"
              />
            </transition>
          </p>
          <div
            v-for="(conf, index) in endedConferences"
            :key="index"
            class="row-box"
          >
            <div class="A_icon-box row-icon">
              <img
                src="@/assets/img/ongoing.svg"
                width="16"
                height="16"
              >
            </div>
            <p class="row-name">
              {{ conf.name }}
            </p>
            <p class="row-datetime">
              {{ $t('Conferences.ended') }}
              {{ conf.datetime }}
            </p>
            <p class="row-duration">
              {{ $t('Conferences.duration') }} {{ conf.duration }}
            </p>
            <ParticipantsBubbleButton
              v-if="conf.participants"
              class="participants-bubble-button-component"
              :participants="conf.participants"
              @click.native.prevent
            />
            <div class="row-action">
              <button class="row-action-button">
                <img
                  src="@/assets/img/download.svg"
                  alt="Download"
                  width="24"
                  height="24"
                >
              </button>
            </div>
          </div>
        </div> -->
      </div>
    </section>
    <div class="create-button-container">
      <button
        class="create-button-below-small"
        :class="{ 'create-button-below-small--is-loading': isCreateRoomPending }"
        :disabled="isCreateRoomPending"
        @click="createQuickRoom"
      >
        <img
          v-show="!isCreateRoomPending"
          src="@/assets/img/plus-white.svg"
          width="20"
          height="20"
        >
      </button>
      <ds-button
        class="create-button-above-small"
        size="big"
        :loading="isCreateRoomPending"
        @click="createQuickRoom"
      >
        {{ $t('Conferences.createButton') }}
      </ds-button>
    </div>
  </div>
</template>

<script>
import ParticipantsBubbleButton from '@/components/ParticipantsBubbleButton'
import { mapState, mapGetters } from 'vuex'

export default {
  name: 'Conferences',
  components: {
    ParticipantsBubbleButton
  },
  data () {
    return {
      isCreateRoomPending: false
    }
  },
  computed: {
    ...mapState('rooms', {
      loading: (state) => state.loading
    }),
    ...mapGetters('rooms', [
      'permanentRooms',
      'ongoingConferences',
      'upcomingConferences',
      'endedConferences',
      'duration'
    ])
  },
  mounted () {
    if (this.$route.params.start) {
      this.createQuickRoom()
    }
  },
  methods: {
    async createQuickRoom () {
      // disable the buttons
      this.isCreateRoomPending = true

      try {
        const result = await this.$store.dispatch('rooms/create')
        this.$router.push({ name: 'Conference', params: { id: result.hash } })
      } catch (err) {
        this.$store.commit('app/openErrorPopin', err)
        this.isCreateRoomPending = false
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.view-root {
  padding: 20px 20px 100px;

  @include above(small) {
    padding: 50px 30px;
  }
}

.rows-container:not(:first-child) {
  margin-top: 40px;
}

.row-box {
  position: relative;
  display: block;
  margin-top: 10px;
  padding: 15px 15px 15px 65px;
  background-color: white;
  border-radius: 20px;

  @include above(small) {
    display: flex;
    align-items: center;
    padding: 20px;
  }

  &.row-box--empty {
    padding: 24px 20px;
    background-color: rgba(255, 255, 255, 0.36);
    color: $color-gray-mid;
    font-weight: 500;
    font-size: 14px;
    line-height: 1.25;
    letter-spacing: 0.1px;
    text-align: center;
  }
}

.row-icon {
  @include below(small) {
    position: absolute;
    top: 15px;
    left: 15px;
  }
}

.row-name {
  letter-spacing: 0.1px;
  line-height: 1.25;

  @include below(small) {
    margin-bottom: 20px;
  }

  @include above(small) {
    margin-left: 10px;
    width: 24%;
  }
}

.row-datetime {
  color: $color-gray-mid;
  font-weight: 600;
  font-size: 12px;
  line-height: 1.25;

  @include below(small) {
    margin-bottom: 10px;
  }

  @include above(small) {
    margin-left: 20px;
    width: 23%;
  }
}

.row-duration {
  color: $color-gray-mid;
  font-weight: 600;
  font-size: 12px;
  line-height: 1.25;

  @include above(small) {
    margin-left: 20px;
    width: 22%;
  }
}

.participants-bubble-button-component {
  @include below(small) {
    margin-top: 20px;
  }

  @include above(small) {
    margin-right: 20px;
  }
}

.row-action {
  margin-left: auto;
  color: $color-blue;
  font-weight: 600;
  font-size: 12px;
  line-height: 1.25;
  white-space: nowrap;

  @include below(small) {
    display: none;
  }
}

.row-action-button {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;
  border-radius: 10px;
}

.create-button-container {
  @include below(small) {
    position: fixed;
    bottom: 20px;
    right: 20px;
    line-height: 0;
  }

  @include above(small) {
    display: flex;
    justify-content: center;
    margin-top: 90px
  }
}

.create-button-below-small {
  position: relative;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 60px;
  height: 60px;
  border-radius: 15px;
  background-color: $color-blue;

  &.create-button-below-small--is-loading {
    color: transparent;
    cursor: default;

    &::before {
      content: "";
      position: absolute;
      top: 50%;
      left: 50%;
      width: 30px;
      height: 30px;
      border-radius: 50%;
      border-top: 2px solid white;
      border-right: 2px solid white;
      border-bottom: 2px solid transparent;
      border-left: 2px solid transparent;
      animation: create_button_below_small_animation 920ms linear infinite;
    }
  }

  @include above(small) {
    display: none;
  }
}

@keyframes create_button_below_small_animation {
  from {
    transform: translateX(-50%) translateY(-50%) rotate(0deg);
  }
  to {
    transform: translateX(-50%) translateY(-50%) rotate(360deg);
  }
}

.create-button-above-small {
  @include below(small) {
    display: none;
  }
}
</style>
