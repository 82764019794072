<template>
  <div>
    <div
      v-if="isOpened"
      class="sidebar-shadow"
      @click="$emit('change', false)"
    />
    <div
      class="sidebar-box"
      :class="{ 'sidebar-box--is-opened': isOpened }"
    >
      <div class="room-name-container">
        <ds-input
          v-model.trim="roomName"
          type="text"
          class="room-name-field"
          :placeholder="$t('EditConfSidebar.confTitlePlaceholder')"
        />
        <ds-button
          size="medium"
          :loading="saving"
          @click="save"
        >
          {{ $t('EditConfSidebar.saveNameButton') }}
        </ds-button>
      </div>
      <hr class="sidebar-hr">
      <div>
        <ds-switch
          v-model="isNotifyConnectActivated"
          :label="$t('EditConfSidebar.notifyConnect')"
        />
      </div>
      <div style="margin-top: 20px;">
        <ds-switch
          v-model="isConfReminderActivated"
          :label="$t('EditConfSidebar.notifyConfReminder')"
        />
      </div>
      <hr class="sidebar-hr">
      <div class="media-container">
        <input
          ref="fileInput"
          type="file"
          accept="audio/*"
          style="display: none;"
          @input="fileChosen"
        >
        {{ $t('EditConfSidebar.customWelcomeMessage') }}
        <div
          v-if="!$store.state.session.userPremium"
          class="premium-note"
        >
          {{ $t('EditConfSidebar.premiumOnlyFeature') }}
        </div>
        <div class="player-container">
          <vue-loading
            v-if="mediaLoading"
            type="bars"
            color="#20bf6b"
          />
          <audio
            v-if="room && room.medias && room.medias.welcome && room.medias.welcome.url && !mediaLoading"
            class="audio-player"
            controls
          >
            <source
              :src="room.medias.welcome.url"
              type="audio/mpeg"
            >
          </audio>
        </div>
        <ds-button
          size="small"
          :disabled="mediaLoading || !$store.state.session.userPremium"
          style="margin-right: 10px;"
          @click="$refs.fileInput.click()"
        >
          <template v-if="room && room.medias && room.medias.welcome && room.medias.welcome.id">
            {{ $t('EditConfSidebar.changeButton') }}
          </template>
          <template v-else>
            {{ $t('EditConfSidebar.chooseButton') }}
          </template>
        </ds-button>
        <ds-button
          danger
          size="small"
          :disabled="mediaLoading || (!room || !room.medias || !room.medias.welcome)"
          @click="removeMediaWelcome"
        >
          {{ $t('EditConfSidebar.deleteButton') }}
        </ds-button>
      </div>
      <hr class="sidebar-hr">
      <div
        v-if="room && room.invited && room.invited.length > 0"
        class="participants-container"
      >
        <p class="participants-heading">
          {{ $t('EditConfSidebar.guestsHeading') }}
        </p>
        <div
          v-for="(invited, index) in room.invited"
          :key="index"
          class="participant-container"
        >
          <PersonAvatar class="participant-person-avatar" />
          <div class="participant-body">
            <p class="participant-name">
              {{ invited.name }}
              <small>{{ $t('EditConfSidebar.invitationDatePrefix') }} {{ invited.datetime | datetime }}</small>
            </p>
            <p class="participant-email">
              {{ invited.email }}
            </p>
          </div>
          <ds-button
            style="margin-left: 15px;"
            size="small"
            danger
            :disabled="invitedLoading[invited.id]"
            @click="removeInvitation(invited.id)"
          >
            {{ $t('EditConfSidebar.removeButton') }}
          </ds-button>
        </div>
      </div>
      <div class="add-participant-button-container">
        <ds-button
          size="medium"
          @click="isLocalInvitePopinOpened = true"
        >
          <img
            src="@/assets/img/add-user-white.svg"
            width="22"
            height="22"
          >
          <span>
            {{ $t('EditConfSidebar.addParticipantButton') }}
          </span>
        </ds-button>
      </div>
      <button
        class="sidebar-cross-button"
        @click="$emit('change', false)"
      >
        <img
          src="@/assets/img/cross-gray.svg"
          alt="Close"
          width="24"
          height="24"
        >
      </button>
      <AddParticipantsPopin
        v-if="isLocalInvitePopinOpened"
        :room-id="roomId"
        :invited="room.invited"
        @invited="inviteSent"
        @close="isLocalInvitePopinOpened = false"
      />
    </div>
  </div>
</template>

<script>
import AddParticipantsPopin from '@/components/AddParticipantsPopin.vue'
import PersonAvatar from '@/components/PersonAvatar.vue'
import { dtFormat } from '@/helpers'

const notifyNextMeetingMinutes = 15

export default {
  components: {
    AddParticipantsPopin,
    PersonAvatar
  },
  filters: {
    datetime (v) {
      const options = {
        hour: 'numeric',
        minute: 'numeric',
        year: 'numeric',
        month: 'numeric',
        day: 'numeric'
      }

      return dtFormat(v, options)
    }
  },
  model: {
    prop: 'isOpened',
    event: 'change'
  },
  props: {
    isOpened: {
      type: Boolean,
      required: true
    },
    isInvitePopinOpened: {
      type: Boolean,
      default: false
    },
    roomId: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      saving: false,
      loaded: false,
      isLocalInvitePopinOpened: this.isInvitePopinOpened,
      name: undefined,
      invitedLoading: {},
      mediaLoading: false,
      isNotifyConnectActivated: false,
      isConfReminderActivated: false,
      roomName: ''
    }
  },
  computed: {
    room () {
      return this.$store.state.rooms.rooms[this.roomId] || {}
    }
  },
  watch: {
    isOpened: {
      handler (value) {
        const zIndex = value ? '1' : '2'
        const appHeaderElement = window.document.getElementById('app-header')

        if (appHeaderElement) {
          appHeaderElement.style.zIndex = zIndex
        }
      },
      immediate: true
    },
    room () {
      if (!this.loaded) {
        this.roomName = this.room.name
        this.isNotifyConnectActivated = !!this.room.notify_connect
        this.isConfReminderActivated = !!this.room.notify_next_meeting
        this.loaded = true
      }
    },
    roomId () {
      this.roomName = this.room.name
      this.isNotifyConnectActivated = !!this.room.notify_connect
      this.isConfReminderActivated = !!this.room.notify_next_meeting
    },
    isInvitePopinOpened (value) {
      this.isLocalInvitePopinOpened = value
    },
    isNotifyConnectActivated (value) {
      this.save()
    },
    isConfReminderActivated (value) {
      this.save()
    }
  },
  mounted () {
    // console.log('roomId', this.roomId)
    // console.log('room', JSON.stringify(this.room))

    // when loading this page, the room object may not be ready
    // the watch on room will do the job

    this.roomName = this.room.name || ''
    this.isNotifyConnectActivated = !!this.room.notify_connect
    this.isConfReminderActivated = !!this.room.notify_next_meeting
  },
  methods: {
    async removeInvitation (invitedId) {
      this.$set(this.invitedLoading, invitedId, true)
      try {
        await this.$store.dispatch('rooms/uninvite', {
          roomId: this.roomId,
          invitedId
        })
        await this.$store.dispatch('rooms/refresh', { roomId: this.roomId })
      } catch (err) {
        this.$store.commit('app/openErrorPopin', err)
      } finally {
        this.$set(this.invitedLoading, invitedId, false)
      }
    },
    inviteSent () {
      this.$store.dispatch('rooms/refresh', { roomId: this.roomId })
      this.isLocalInvitePopinOpened = false
    },
    async save () {
      this.saving = true
      try {
        await this.$store.dispatch('rooms/save', {
          roomId: this.roomId,
          properties: {
            name: this.roomName,
            notify_connect: this.isNotifyConnectActivated,
            notify_next_meeting: this.isConfReminderActivated ? notifyNextMeetingMinutes : 0
          }
        })
      } catch (err) {
        this.$store.commit('app/openErrorPopin', err)
      } finally {
        this.saving = false
      }
    },
    async fileChosen (event) {
      if (!event.target.files.length) {
        return
      }

      const file = event.target.files[0]

      this.mediaLoading = true
      try {
        await this.$store.dispatch('rooms/mediaUpload', { roomId: this.roomId, file })
      } catch (err) {
        this.$store.commit('app/openErrorPopin', err)
      } finally {
        this.mediaLoading = false
        this.$refs.fileInput.value = null
      }
    },
    async removeMediaWelcome () {
      this.mediaLoading = true
      try {
        await this.$store.dispatch('rooms/save', {
          roomId: this.roomId,
          properties: {
            media_welcome: 0
          }
        })
      } catch (err) {
        this.$store.commit('app/openErrorPopin', err)
      } finally {
        this.mediaLoading = false
      }
    }
  }
}
</script>

<style lang="scss" scoped>
$sidebar-width-below-small: 90vw;
$sidebar-width-above-small: 552px;

.sidebar-shadow {
  position: fixed;
  z-index: $z-overlay-shadow;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.5);
}

.sidebar-box {
  position: fixed;
  z-index: $z-overlay-box;
  top: 0;
  bottom: 0;
  left: -$sidebar-width-below-small;
  width: $sidebar-width-below-small;
  padding: 64px 20px 20px;
  background-color: white;
  border: 1px solid $color-gray-light;
  overflow-y: auto;
  transition: left $standard-transition-duration;

  @include above(small) {
    left: -$sidebar-width-above-small;
    width: $sidebar-width-above-small;
    padding: 90px 30px 30px;
  }

  &.sidebar-box--is-opened {
    left: 0;
  }
}

.sidebar-hr {
  margin: 30px 0;
  border: none;
  border: 1px solid #d5d5dc;

  @include above(small) {
    margin: 40px 0;
  }
}

.sidebar-cross-button {
  position: absolute;
  top: 20px;
  right: 20px;

  @include above(small) {
    top: 30px;
    right: 30px;
  }

  display: inline-flex;
  justify-content: center;
  align-items: center;
}

.room-name-container {
  @include above(small) {
    display: flex;
    align-items: center;
  }
}

.room-name-field {
  @include below(small) {
    margin-bottom: 20px;
  }

  @include above(small) {
    flex-grow: 1;
    margin-right: 10px;
  }
}

.media-container {
  .player-container {
    margin-top: 10px;
    padding-bottom: 10px;
  }

  .premium-note {
    font-size: 75%;
    opacity: .50;
  }
}

.add-participant-button-container {
  @include above(small) {
    display: flex;
    justify-content: center;
  }
}

.participants-container {
  margin-bottom: 20px;
  max-width: 490px;

  @include above(small) {
    margin-bottom: 50px;
  }
}

.participants-heading {
  font-weight: 500;
  font-size: 14px;
  letter-spacing: 0.1px;

  @include above(small) {
    font-size: 20px;
  }
}

.participant-container {
  display: flex;
  align-items: center;

  &:not(:first-child) {
    margin-top: 10px;
  }
}

.participant-person-avatar {
  @include below(small) {
    display: none;
  }
}

.participant-body {
  margin-right: auto;

  @include above(small) {
    margin-left: 15px;
  }
}

.participant-name {
  font-size: 14px;
  line-height: 16px;
  word-break: break-word;

  small {
    color: $color-gray-soft;
    font-size: 12px;
  }
}

.participant-email {
  margin-top: 5px;
  color: $color-gray-mid;
  font-size: 12px;
  line-height: 14px;
  word-break: break-word;
}

.audio-player {
  padding: 2px;
}
</style>
