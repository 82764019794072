<template>
  <div class="popin-frame">
    <div class="popin-box">
      <p class="heading">
        {{ $t('AddParticipantsPopin.heading') }}
      </p>
      <div class="datetime-field-container">
        <p class="datetime-label">
          {{ $t('AddParticipantsPopin.beginDatetimeLabel') }}
        </p>
        <v-date-picker
          v-model="beginDatetime"
          mode="dateTime"
          :min-date="new Date()"
          :minute-increment="5"
          :locale="$store.getters['session/jsLocale']"
          is24hr
        >
          <template v-slot="{ inputValue, inputEvents }">
            <input
              readonly
              class="DS_input"
              :value="inputValue"
              :placeholder="$t('AddParticipantsPopin.beginDatetimePlaceholder')"
              v-on="inputEvents"
            >
          </template>
        </v-date-picker>
      </div>
      <textarea
        v-model.trim="message"
        class="textarea"
        rows="3"
        :placeholder="$t('AddParticipantsPopin.messagePlaceholder')"
      />
      <p class="subline">
        {{ $t('AddParticipantsPopin.subline') }}
      </p>
      <textarea
        v-model.trim="emails"
        class="textarea"
        rows="6"
        :placeholder="$t('AddParticipantsPopin.textareaPlaceholder')"
      />
      <div
        v-if="participants.length"
        class="participants-container"
      >
        <p class="T_medium-heading">
          {{ $t('AddParticipantsPopin.participantsHeading') }}
        </p>
        <div
          v-for="(participant, index) in participants"
          :key="index"
          class="participant-container"
        >
          <ds-input
            :value="participant.name"
            type="text"
            class="participant-name-field"
            :placeholder="$t('AddParticipantsPopin.participantNamePlaceholder')"
            gray
            @change="onChangeOnParticipantName($event, index)"
          />
          <p class="participant-email">
            {{ participant.email }}
          </p>
          <ds-button
            size="small"
            danger
            @click="removeParticipant(index)"
          >
            {{ $t('AddParticipantsPopin.removeButton') }}
          </ds-button>
        </div>
      </div>
      <div class="validate-button-container">
        <ds-button
          size="large"
          fluid
          :disabled="loading"
          :loading="loading"
          @click="invite"
        >
          {{ $t('AddParticipantsPopin.validateButton') }}
        </ds-button>
        <button
          class="A_mini-button"
          @click="close"
        >
          {{ $t('AddParticipantsPopin.cancelButton') }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
const tomorrow9am = new Date()
tomorrow9am.setDate(tomorrow9am.getDate() + 1) // tomorrow
tomorrow9am.setHours(9, 0, 0, 0) // 9am

export default {
  props: {
    roomId: {
      type: String,
      required: true
    },
    invited: {
      type: Array,
      required: true
    }
  },
  data () {
    return {
      beginDatetime: tomorrow9am,
      message: '',
      emails: '',
      participants: [],
      loading: false
    }
  },
  computed: {
    inviteDatetimeUTC () {
      let date = this.beginDatetime.getUTCDate()
      let month = this.beginDatetime.getUTCMonth() + 1
      const year = this.beginDatetime.getUTCFullYear()
      let hours = this.beginDatetime.getUTCHours()
      let minutes = this.beginDatetime.getUTCMinutes()

      if (date < 10) date = `0${date}`
      if (month < 10) month = `0${month}`
      if (hours < 10) hours = `0${hours}`
      if (minutes < 10) minutes = `0${minutes}`

      // YYYY-MM-DD HH:mm::ss
      return `${year}-${month}-${date} ${hours}:${minutes}:00`
    }
  },
  watch: {
    emails (value) {
      const matches = value.matchAll(/(([A-Z0-9._%+-]+)@[A-Z0-9.-]+\.[A-Z]{2,63})/gi)
      const result = []
      const emails = new Set()

      if (matches) {
        for (const match of matches) {
          const email = match[1]

          if (!emails.has(email)) {
            result.push({

              name: match[2]
                .replace(/[._%+-]/g, ' ')
                .replace(/(^\w{1})|(\s+\w{1})/g, (letter) => letter.toUpperCase()) || '',
              email
            })

            emails.add(email)
          }
        }
      }

      this.participants = result
    }
  },
  methods: {
    close () {
      // close the popin
      this.$emit('close')

      this.reset()
    },
    reset () {
      // reset values
      this.emails = ''
      this.message = ''
      this.participants = []
      this.beginDatetime = tomorrow9am
    },
    onChangeOnParticipantName (event, index) {
      this.participants[index].name = event.target.value.trim()
    },
    removeParticipant (index) {
      this.emails = this.emails
        .replaceAll(this.participants[index].email, '')
        .replaceAll(/[\r\n]{2,}/g, '\n')
    },
    async invite () {
      if (!this.participants.length) {
        this.close()
        return
      }

      this.loading = true

      const errors = await this.$store.dispatch('rooms/invite', {
        roomId: this.roomId,
        message: this.message,
        datetime: this.inviteDatetimeUTC,
        participants: this.participants
      })

      if (errors.length > 0) {
        this.$store.commit('app/openErrorPopin', {
          message: this.$t('AddParticipantsPopin.genericError')
        })
      }

      this.$emit('invited')
      this.close()
      this.loading = false
    }
  }
}
</script>

<style lang="scss" scoped>
.popin-frame {
  position: fixed;
  z-index: $z-overlay-shadow;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 20px;

  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
}

.popin-box {
  padding: 30px 20px;
  width: 575px;
  max-width: 100%;
  max-height: 90vh;
  border-radius: 20px;
  background-color: white;
  overflow-y: auto;
}

.heading {
  font-weight: 600;
  font-size: 18px;
}

.subline {
  margin-top: 25px;
  margin-bottom: -20px;
  color: $color-gray-mid;
  font-size: 11px;
}

.textarea {
  display: block;
  margin-top: 25px;
  padding: 9px 15px;
  width: 100%;
  min-height: 38px; // = 1 + 9 + 18 + 9 + 1
  max-height: 30vh;
  background-color: $color-fake-white;
  border: 1px solid $color-fake-white;
  border-radius: 15px;
  color: $color-gray-dark;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.1px;
}

.participants-container {
  margin-top: 40px;
}

.participant-container {
  margin-top: 20px;

  @include above(small) {
    display: flex;
    align-items: center;
    margin-top: 10px;
  }

  &:first-of-type {
    margin-top: 24px;
  }
}

.participant-name-field {
  @include above(small) {
    flex-shrink: 0;
    width: 200px;
  }
}

.participant-email {
  font-size: 12px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  @include below(small) {
    margin-top: 8px;
    margin-bottom: 8px;
  }

  @include above(small) {
    margin-left: 15px;
    margin-right: auto;
    padding-right: 10px;
  }
}

.validate-button-container {
  margin-top: 24px;
  text-align: center;

  @include above(small) {
    margin-top: 50px;
  }

  > *:not(:first-child) {
    margin-top: 10px;
  }
}

.datetime-field-container {
  margin-top: 20px;

  @include above(small) {
    display: flex;
    align-items: center;

    > * {
      flex-grow: 1;
    }
  }
}

.datetime-label {
  @include below(small) {
    margin-bottom: 10px;
  }

  @include above(small) {
    margin-right: 20px;
    text-align: right;
  }
}
</style>
